.App {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  font-family: "Poppins", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.css-e0akj8-ValueContainer {
  padding: 4px !important;
  height: 100% !important;
  overflow: hidden !important;
}

.css-r1vqq7-ValueContainer {
  /* padding: 0%!important; */
  /* display: flex; */
  height: 100% !important;
  overflow: hidden !important;
  padding: 4px !important;
  /* align-items: center; */
}

.css-r1vqq7-ValueContainer:first-child {
  height: 100%;
  display: flex;
  align-items: center;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 100%;
}

.css-qbdosj-Input {
  margin: 0% !important;
  height: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chat Message Card Action hover render */
.chat-menu-button {
  transition: all 100ms ease-in;
  visibility: hidden;
  opacity: 0;
}

.chat-menu:hover .chat-menu-button {
  visibility: visible;
  opacity: 1;
}

.reply-icon {
  transition: all 100ms ease-in;
  visibility: hidden;
  opacity: 0;
}

.replay-icon-parent:hover .reply-icon {
  visibility: visible;
  opacity: 1;
}
/* Chat Message Card Action hover render */
@keyframes parentMessageBackground {
  from {
    background-color: #e2e8f0;
  }
}

.show-parent-id {
  /* background-color: #e2e8f0; */
  transition: all;
  animation: parentMessageBackground 2s ease-in-out;
}

.text-focus-none:focus {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}
/*  */
.jodit-status-bar {
  display: none !important;
}

/* Team view hover status card */
.status-card {
  opacity: 0;
  visibility: hidden;
  transition: all 100ms ease-in;
}

.status-cunter:hover .status-card {
  visibility: visible;
  opacity: 1;
}

/* Login invalid password  Shake */
.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/* Logo Glow */
.logo-glow {
  text-shadow:
    0 0 30px rgba(255, 255, 255, 0.6),
    0 0 60px rgba(255, 255, 255, 0.45),
    0 0 110px rgba(255, 255, 255, 0.25),
    0 0 100px rgba(255, 255, 255, 0.1);
}

/* Flick Login Codiotic Blink */
/* ---------------------------------------------- */
.text-flicker-in-glow {
  -webkit-animation: text-flicker-in-glow 4s linear both;
  animation: text-flicker-in-glow 4s linear both;
}

@-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.6;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4),
      0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4),
      0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45),
      0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45),
      0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}
@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4),
      0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4),
      0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45),
      0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow:
      0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45),
      0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}

/* Markdown Editor class */
.prose {
  color: inherit;
  max-width: inherit;
  min-width: inherit;
  /* min-height: 250px; */
  z-index: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  /* border: 1px solid rgb(232, 232, 232); */
}
.prose.data-lexical-editor {
  color: inherit;
  max-width: inherit;
  min-width: inherit;
  height: 100%;
  z-index: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  /* border: 1px solid rgb(232, 232, 232); */
}
.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin: 0;
}
.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin: 0;
}
.fit-height .prose{
 max-height: 250px  ;
overflow: scroll;
}

.main-message
  .prose
  :where(ol > li):not(
    :where([class~="not-prose"], [class~="not-prose"] *)
  )::marker {
  font-weight: 400;
  color: #fff !important;
  /* color: var(--tw-prose-counters); */
}

.prose h1 {
  color: inherit !important;
  margin: 0 !important;
}
.prose h2 {
  color: inherit !important;
  margin: 0 !important;
}
.prose h3 {
  color: inherit !important;
  margin: 0 !important;
}
.prose h4 {
  color: inherit !important;
  margin: 0 !important;
}
.prose strong {
  color: inherit !important;
}
.prose blockquote {
  color: inherit !important;
}
.prose img {
  margin: 0 !important;
}

/* ._dialogContent_11eqz_556 {
  z-index: 4000;
} */

[class^="_dialogContent_"] {
  z-index: 4000 !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 50 !important;
}

.bg-img-loginui {
  background-image: url("../public/ffflurry.svg");
  background-size: cover;
  z-index: 10;
} 

.bg-birthDay {
  background-image: url('../public/birthDay2.jpg');
 background-size: cover;
 background-position: center right;
 width: 100%;
 height: 100%;
 background-repeat: no-repeat;
 opacity: .8;
}

.calendarFilter .css-knqc4i-MuiDialogActions-root {
  display: none !important;
}
.calendarFilter .css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  display: none !important;
}
.calendarFilter .css-epd502 {
  margin-left: 10px !important;
}

/* ._dialogContent_1xgxv_541{
  z-index: 1000000000;
} */

/***** When user hover on the Menu bell icon then task panel opend with transition *****/

.child-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  transition: all 150ms ease-out;
  transition-delay: 100ms;
  visibility: hidden;
  /* visibility: visible; */

  background: linear-gradient(
    to left,
    rgba(25, 27, 60, 0) -297%,
    rgba(0, 0, 0, 1) 181%
  );
  opacity: 1;
}

.menu-parent:hover > .child-menu {
  opacity: 1;
  visibility: visible;
}


.parent {
  position: relative; /* Ensure parent is positioned relatively */
}

.child {
  position: absolute;
  top: 100%; /* Position the child div below the parent */
  left: 0; /* Align the child div with the left edge of the parent */
  visibility: hidden;
  background-color: white;
  width: 200px;
}

.parent:hover .child {
  visibility: visible;
}


.meeting .prose{
  max-height: 350px;
  overflow: scroll;
}

.fullScreenEditor .prose{
  min-height: 60vh!important;
}
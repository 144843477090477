@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-light: #196674;
  --primary-main: #0c4349;
  --primary-dark: #041618;
  --secondary-main: #ff7;
  --divider: #d1d5db;
  --hover: #f9fafb;
  --surface-light: #f9fafb;
  --surface: #f3f4f6;
  --surface-dark: #e5e7eb;
  --neutral: #6b7280;
  --blue-cobra: #0078d4;
}

:root,
.light,
.light-theme {
  --blue-1: #fcfdff;
  --blue-2: #f6f9ff;
  --blue-3: #eaf2ff;
  --blue-4: #ddeaff;
  --blue-5: #cddfff;
  --blue-6: #b6d1ff;
  --blue-7: #9abeff;
  --blue-8: #77a4ff;
  --blue-9: #194afc;
  --blue-10: #1641db;
  --blue-11: #2050ea;
  --blue-12: #132a6b;

  --blue-a1: #0055ff03;
  --blue-a2: #0055ff09;
  --blue-a3: #0062ff15;
  --blue-a4: #0062ff22;
  --blue-a5: #005cff32;
  --blue-a6: #005fff49;
  --blue-a7: #005bff65;
  --blue-a8: #0055ff88;
  --blue-a9: #0036fce6;
  --blue-a10: #002fd8e9;
  --blue-a11: #0037e7df;
  --blue-a12: #00195fec;

  --blue-contrast: #fff;
  --blue-surface: #f4f8ffcc;
  --blue-indicator: #194afc;
  --blue-track: #194afc;
}

:root,
.light,
.light-theme {
  --gray-1: #fcfcfd;
  --gray-2: #f9f9fb;
  --gray-3: #eff0f3;
  --gray-4: #e7e8ec;
  --gray-5: #e0e1e6;
  --gray-6: #d8d9e0;
  --gray-7: #cdced7;
  --gray-8: #b9bbc6;
  --gray-9: #8b8d98;
  --gray-10: #80828d;
  --gray-11: #62636c;
  --gray-12: #1e1f24;

  --gray-a1: #00005503;
  --gray-a2: #00005506;
  --gray-a3: #00104010;
  --gray-a4: #000b3618;
  --gray-a5: #0009321f;
  --gray-a6: #00073527;
  --gray-a7: #00063332;
  --gray-a8: #00083046;
  --gray-a9: #00051d74;
  --gray-a10: #00051b7f;
  --gray-a11: #0002119d;
  --gray-a12: #000107e1;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #8b8d98;
  --gray-track: #8b8d98;
}

:root,
.light,
.light-theme {
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #ffebe9;
  --red-4: #ffdcd7;
  --red-5: #ffcdc7;
  --red-6: #febdb7;
  --red-7: #f5a9a2;
  --red-8: #ec8f86;
  --red-9: #dd3c3c;
  --red-10: #cf292e;
  --red-11: #cf2c30;
  --red-12: #641918;

  --red-a1: #ff000003;
  --red-a2: #ff000008;
  --red-a3: #ff180016;
  --red-a4: #ff200028;
  --red-a5: #ff1c0038;
  --red-a6: #fc160048;
  --red-a7: #e414005d;
  --red-a8: #d7130079;
  --red-a9: #d30000c3;
  --red-a10: #c60006d6;
  --red-a11: #c50005d3;
  --red-a12: #540100e7;

  --red-contrast: #fff;
  --red-surface: #fff5f5cc;
  --red-indicator: #dd3c3c;
  --red-track: #dd3c3c;
}

:root,
.light,
.light-theme {
  --black-1: #fcfcfc;
  --black-2: #f9f9f9;
  --black-3: #f0f0f0;
  --black-4: #e8e8e8;
  --black-5: #e1e1e1;
  --black-6: #d9d9d9;
  --black-7: #cecece;
  --black-8: #bbb;
  --black-9: #121212;
  --black-10: #2b2b2b;
  --black-11: #636363;
  --black-12: #1f1f1f;

  --black-a1: #00000003;
  --black-a2: #00000006;
  --black-a3: #0000000f;
  --black-a4: #00000017;
  --black-a5: #0000001e;
  --black-a6: #00000026;
  --black-a7: #00000031;
  --black-a8: #00000044;
  --black-a9: #000000ed;
  --black-a10: #000000d4;
  --black-a11: #0000009c;
  --black-a12: #000000e0;

  --black-contrast: #fff;
  --black-surface: #f8f8f8cc;
  --black-indicator: #121212;
  --black-track: #121212;
}
@import url("https://fonts.googleapis.com/css2?family=Heebo&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* ROBOTO FONTS  */
  font-family: "Heebo", sans-serif;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-thumb {
  /* width: 5px!important; */
  background-color: #a0a0a0 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  margin-top: 10px !important;
  padding-top: 5px !important;
}

.vertical {
  writing-mode: vertical-lr;
}

/* mark down editor */
/* .prose {
  max-width: inherit;
  min-width: inherit;
  min-height: 20px;
} */

.custom-shadow {
  border: 0.5px solid var(--Border, #d1d5db);
  box-shadow: 0px 1px 0.5px 0px rgba(18, 18, 18, 0.3);
}

.page-break {
  page-break-inside: avoid;
}

.page-break-before {
  page-break-before: always;
}

@media print {
  body {
    margin: 10px;
  }
}

/* .Mui-focused {
  border: none !important;
  outline: none;
} */

/* popup position and backdrop gradiant and rounded*/
.css-hz1bth-MuiDialog-container {
  align-items: start !important;
}
.backdropGradiant {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 20%,
    rgba(0, 0, 0, 0.11808473389355745) 0100%
  ) !important;
}
.css-twia2z-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}

/* ATM select  */
.css-e9hzhs-control {
  border-radius: 0.5rem !important ;
  border: 1px solid var(--gray-8) !important;
}
.css-r51pfg-control {
  border-radius: 0.5rem !important ;
  border: 2px solid var(--blue-8) !important;
}

/* ATM Date picker  */
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0.5rem !important;
}

/* //// */
.custom-select  .css-e9hzhs-control , .css-r51pfg-control {
  border: none !important;
  border-color: transparent !important ;
} 
.custom-select  .css-e9hzhs-control:focus{
  outline: none !important;
}

 .custom-select .css-1wy0on6{
  display: none !important;
 }
 .custom-select .css-f91s3b-container{
  min-width: fit-content !important;
 }
 .notelogDialog .css-tlc64q-MuiPaper-root-MuiDialog-paper{
  position: absolute !important;
  bottom:100px !important;
  right: 50px !important;
 }
